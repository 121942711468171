import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.skierRaceWhiteMan]: {
    ext: 'ktx2',
    version: 2,
    femaleVersion: 2,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoMan]: {
    ext: 'ktx2',
    version: 2,
    femaleVersion: 2,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackMan]: {
    ext: 'ktx2',
    version: 2,
    femaleVersion: 2,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackWoman]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothes]: {
    ext: 'ktx2',
    version: 2,
    femaleVersion: 2,
    genderActive: true,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothesIbu]: {
    ext: 'ktx2',
    genderActive: true,
    version: 2,
    femaleVersion: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.hill]: {
    version: 1,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.transparent]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/`
  },
  [TexturesNames.skybox]: {
    ext: 'jpg',
    version: 4,
    cubemap: true,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.ads]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu1]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu2]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu3]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu4]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu5]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu6]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.adsIbu7]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.flags]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.track]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.lightmapHill]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    version: 241212,
    returnTextureLightmap: true
  },
  [TexturesNames.staticPeople]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.rifle]: {
    version: 10,
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.rocks]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.impact]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.startAds]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.startAdsIbu]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.snowParticle]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.targetCoverShadow]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  }
}
